import React from "react"
import HeroWithLogo from "../components/HeroWithLogo"

import Layout from "../components/Layout"
import NavWithLogo from "../components/Layout/NavWithLogo"
import SeoWithLanguage from "../components/SeoWithLanguage"
import Team from "./Team"

export default function TeamPage() {
  return (
    <Layout nav={<NavWithLogo />}>
      <SeoWithLanguage title="Team" />
      <HeroWithLogo />
      <Team />
    </Layout>
  )
}
