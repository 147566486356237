import { createStyles, withStyles } from "@material-ui/core"

import Wrapper, {
  wrapperAlignedRightStyles,
} from "../../components/CardList/Wrapper"

const styles = () => {
  return createStyles({
    root: {
      ...wrapperAlignedRightStyles,
    },
  })
}

export default withStyles(styles)(Wrapper)
