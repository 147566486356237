import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
} from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

import TeamMember from "../../types/TeamMember"
import { useHomeLanguageText } from "../../utils/language"
import { flexBreakpoint } from "./styles"
import { QueryWithNodes } from "../../types/queries"
import CardList from "../../components/CardList"
import { ItemData } from "../../components/Card/types"
import Wrapper from "./Wrapper"

const styles = ({ breakpoints: { up } }: Theme) => {
  const flexBreakpointUp = up(flexBreakpoint)
  const lg = up("lg")
  const xl = up("xl")

  return createStyles({
    root: {
      marginBottom: "7.75rem",
      [lg]: {
        marginBottom: "10.75rem",
      },
      [xl]: {
        marginBottom: "19.75rem",
      },
    },
    title: {
      marginBottom: "2.25rem",
      [lg]: {
        marginBottom: "3.125rem",
        fontSize: "2.875rem",
        lineHeight: "2.9375rem",
      },
    },
    list: {
      margin: 0,
      padding: 0,
      [flexBreakpointUp]: {
        display: "flex",
        flexWrap: "wrap",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

interface Data {
  allStrapiUser: QueryWithNodes<TeamMember>
}

const Team = ({ classes }: Props) => {
  const {
    allStrapiUser: { nodes: teamMembers },
  }: Data = useStaticQuery(graphql`
    query Team {
      allStrapiUser(
        sort: { order: ASC, fields: teamMemberOrder }
        filter: { isTeamMember: { eq: true } }
      ) {
        nodes {
          jobTitle
          homeRegion
          firstName
          lastName
          id: strapiId
          displayPicture {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 0.75, width: 600)
              }
            }
          }
        }
      }
    }
  `)

  const getItemData = ({
    firstName,
    lastName,
    jobTitle,
    displayPicture: { localFile, alternativeText },
  }: TeamMember): ItemData => {
    const name = `${firstName} ${lastName}`
    return {
      title: name,
      description: jobTitle,
      alt: alternativeText,
      file: localFile,
    }
  }

  const addMemberData = (
    members: ItemData[],
    teamMember: TeamMember
  ): ItemData[] => {
    if (!teamMember.displayPicture) return members
    const memberData: ItemData = getItemData(teamMember)
    return [...members, memberData]
  }

  const {
    team: { title },
  } = useHomeLanguageText()

  const teamMemberData: ItemData[] = teamMembers.reduce(addMemberData, [])

  return (
    <section className={classes.root}>
      <Typography variant="h2" className={classes.title} align="center">
        {title}
      </Typography>
      <CardList items={teamMemberData} Wrapper={Wrapper} />
    </section>
  )
}

export default withStyles(styles)(Team)
